import React, { Component } from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import App from "../components/App"
import Navigation from "../components/Navigation"
import Content from "../components/Content"
import Footer from "../components/Footer"

class NotFoundPage extends Component {
  render() {
    const { content } = this.props.data.allContentful404Page.edges[0].node
    const navbar = this.props.data.allContentfulNavbar.edges[0].node
    const footerData = this.props.data.allContentfulFooter.edges[0].node

    return (
      <App navbar={navbar}>
        <Container fluid className="hero-nav">
          <Navigation color="dark" menus={navbar.menus} />
        </Container>

        <Content content={content} />
        <Footer footer={footerData} />
      </App>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentful404Page {
      edges {
        node {
          id
          title
          content {
            title
            centerAlignText
            topPadding
            bottomPadding
            narrowColumn
            color
            verticalAlignment
            flipColumnsOnMobile
            column1Content {
              json
            }
            column2Content {
              json
            }
            column3Content {
              json
            }
            column4Content {
              json
            }
          }
        }
      }
    }
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`
